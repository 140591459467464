import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  BackButton,
  Blank,
  BottomFixed,
  CloseButton,
  Label,
  PhoneNumberField,
  Section,
  TopNavbar,
} from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { FeedsDetail } from 'src/components/common/FeedsDetail';
import { TextInput } from 'src/components/common/TextInput';
import { useStudentNewsletterAdd } from 'src/container/student-newsletter-add';
import { useStudentNewsletterDetail } from 'src/container/student-newsletter-detail';
import { NewsletterType, RequestUpsertStudentNewsletterDto, Role } from 'src/generated/model';
import { useSignature } from 'src/hooks/useSignature';
import { meState, toastState } from 'src/store';
import { DateFormat, DateUtil } from 'src/util/date';
import { isPdfFile } from 'src/util/file';
import { NewsletterAddPage } from './NewsletterAddPage';

export function NewsletterDetailPage() {
  const { push } = useHistory();
  let { id } = useParams<{ id: string }>();
  id = id.split('/')[0];

  const meRecoil = useRecoilValue(meState);

  const [nokName, setNokName] = useState('');

  const setToastMsg = useSetRecoilState(toastState);

  const [nokPhone, setNokPhone] = useState('');

  useEffect(() => {
    if (meRecoil) {
      setNokName(meRecoil?.role === Role.PARENT ? meRecoil?.name : meRecoil?.nokName);
      setNokPhone(meRecoil?.role === Role.PARENT ? meRecoil?.phone : meRecoil?.nokPhone);
    }
  }, [meRecoil]);

  const { newsletter, studentNewsletter, isLoading, refetch } = useStudentNewsletterDetail(+id);

  const { canvasRef, sigPadData, clearSignature } = useSignature();
  const [openSignModal, setOpenSignModal] = useState(false);

  const { upsertStudentNewsletter, isLoading: isLoadingAdd, errorMessage } = useStudentNewsletterAdd(newsletter?.id);

  const [content, setContent] = useState<any>(() => {
    return studentNewsletter?.content ? JSON.parse(studentNewsletter.content) : {};
  });

  useEffect(() => {
    if (studentNewsletter?.content) {
      setContent(JSON.parse(studentNewsletter.content));
    }
  }, [studentNewsletter]);

  const [isUpdateState, setUpdateState] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasPdfModalOpen, setPdfModalOpen] = useState(false);
  const [focusPdfFile, setFocusPdfFile] = useState('');

  const isNotParent = meRecoil?.role !== Role.PARENT;

  const images = newsletter?.images.filter((image) => !isPdfFile(image)) || [];
  const Pdfs = newsletter?.images.filter((image) => isPdfFile(image)) || [];
  const documents = newsletter?.files || [];

  const handleSubmit = () => {
    if (newsletter?.surveyContent) {
      const surveyContent = JSON.parse(newsletter.surveyContent);

      let noAnswer = 0;

      surveyContent.map((element: any, index: number) => {
        if (element.required && noAnswer === 0) {
          if (content[index + 1] === undefined) {
            noAnswer = index + 1;
          }
        }
      });

      if (noAnswer > 0) {
        setToastMsg(noAnswer.toString() + '번 항목을 입력해주세요.');
        return;
      }
    }

    const data: RequestUpsertStudentNewsletterDto = {
      nokName: nokName,
      nokPhone: nokPhone,
      newsletterId: newsletter?.id || 0,
      content: JSON.stringify(content),
      studentSignature: sigPadData,
    };

    const regExp = /^010(?:\d{4})\d{4}$/;
    if (nokPhone && !regExp.test(nokPhone.replace(/-/g, ''))) {
      alert('보호자 연락처를 확인해 주세요.');
      return;
    }

    if (studentNewsletter) {
      upsertStudentNewsletter(data);
      setUpdateState(false);
    } else {
      setOpenSignModal(true);
    }
  };

  const buttonDisabled =
    isNotParent && !nokPhone && (newsletter?.surveyContent ? !Object.values(content).every((v) => v !== '') : false);

  const handleSubmitWithSign = () => {
    if (!sigPadData) {
      alert('서명이 없습니다. 서명을 해주세요.');
      return;
    }

    const data: RequestUpsertStudentNewsletterDto = {
      nokName: nokName,
      nokPhone: nokPhone,
      newsletterId: newsletter?.id || 0,
      content: JSON.stringify(content),
      studentSignature: sigPadData,
    };
    upsertStudentNewsletter(data);
    setOpenSignModal(false);
    setUpdateState(false);
  };

  if (isUpdateState && newsletter) {
    return (
      <NewsletterAddPage
        studentNewsletterData={studentNewsletter}
        newsletterData={newsletter}
        setUpdateState={(b: boolean) => {
          setUpdateState(b);
          setIsSubmitted(true);
          refetch();
        }}
      />
    );
  }

  return (
    <div>
      {isLoading && <Blank />}
      <TopNavbar
        title="가정통신문"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <FeedsDetail
        category1={newsletter?.category || '가정통신문'}
        category1Color="light_golden"
        category2={newsletter?.type === NewsletterType.NOTICE ? '공지' : '설문'}
        category2Color="lavender_blue"
        sendTo={
          (newsletter?.toStudent ? '학생' : '') +
          (newsletter?.toStudent && newsletter?.toParent ? '/' : '') +
          (newsletter?.toParent ? '보호자' : '')
        }
        sendToColor="gray-100"
        useSubmit={newsletter?.type !== NewsletterType.NOTICE}
        submitDate={DateUtil.formatDate(newsletter?.endAt || '', DateFormat['YYYY.MM.DD HH:mm'])}
        submitYN={studentNewsletter?.isSubmitted || false}
        submitText={
          studentNewsletter?.isSubmitted
            ? '제출일 ' + DateUtil.formatDate(studentNewsletter?.updatedAt || '', DateFormat['YYYY.MM.DD HH:mm'])
            : undefined
        }
        title={newsletter?.title}
        contentText={newsletter?.content}
        contentImages={newsletter?.images}
        contentFiles={newsletter?.files}
        contentSurvey={newsletter?.surveyContent}
        setSurveyResult={(c: any) => setContent(c)}
        surveyResult={content}
        writer={newsletter?.writerName}
        createAt={DateUtil.formatDate(newsletter?.createdAt || '', DateFormat['YYYY.MM.DD HH:mm'])}
      />

      {newsletter?.type === 'STUDENT_PARENTS_SURVEY' &&
        newsletter?.endAt &&
        new Date(newsletter.endAt) > new Date() &&
        studentNewsletter?.isSubmitted && (
          <div className="w-full p-3">
            <Button.lg children="수정하기" onClick={() => setUpdateState(true)} className="filled-primary w-full" />
          </div>
        )}

      {newsletter?.type === 'STUDENT_PARENTS_SURVEY' && studentNewsletter?.isSubmitted !== true && (
        <div className="w-full p-3">
          {newsletter?.toParent && meRecoil?.role === Role.USER && (
            <>
              <Label.col>
                <Label.Text children="보호자 이름" />
                <TextInput
                  value={nokName}
                  disabled={nokName ? true : false}
                  onChange={(e) => setNokName(e.target.value)}
                  className={nokName ? 'border-gray-300' : 'border-red-700'}
                />
              </Label.col>
              <Label.col>
                <Label.Text children="보호자 연락처" />
                <PhoneNumberField
                  value={nokPhone || '010'}
                  disabled={nokPhone !== '' && nokPhone !== '010' && nokPhone.length >= 10}
                  onChange={(e) => setNokPhone(e.target.value)}
                  style={{ borderColor: !nokPhone ? 'rgba(185, 28, 28)' : '' }}
                />
              </Label.col>
            </>
          )}
          <Button.lg
            children="제출하기"
            disabled={buttonDisabled}
            onClick={handleSubmit}
            className="filled-primary w-full"
          />
          {errorMessage && <div className="text-red-600">{errorMessage}</div>}
        </div>
      )}

      <div className={openSignModal ? '' : 'hidden'}>
        <Blank text="" />
        <BottomFixed className="-bottom-4 z-100 rounded-xl">
          <div className="absolute right-3 top-2" onClick={() => setOpenSignModal(false)}>
            <CloseButton
              onClick={() => {
                setOpenSignModal(false);
                clearSignature();
              }}
            />
          </div>
          <Section>
            <div>
              <div className="text-xl font-bold text-gray-700">서명란</div>
              <div className="text-gray-500">아래 네모칸에 이름을 바르게 적어주세요.</div>
            </div>
            <canvas
              ref={canvasRef}
              width={window.innerWidth * 0.9}
              height={window.innerWidth * 0.4 > 280 ? 280 : window.innerWidth * 0.4}
              className="m-auto rounded-[30px] bg-[#F2F2F2]"
            />
            <div className="flex items-center justify-between space-x-2">
              <Button.lg children="다시하기" onClick={() => clearSignature()} className="outlined-primary w-full" />
              <Button.lg children="서명 제출하기" onClick={handleSubmitWithSign} className="filled-primary w-full" />
            </div>
          </Section>
          {isLoading && <Blank />}
        </BottomFixed>
      </div>
    </div>
  );
}
