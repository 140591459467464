import { ChangeEvent, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useCoulselingSendParentSignUpV2, useCoulselingUpdateStudent } from 'src/generated/endpoint';
import { ResponseUserDto, UploadFileTypeEnum } from 'src/generated/model';
import { useFileUpload } from 'src/hooks/useFileUpload';
import { toastState } from 'src/store';
import { errorType } from 'src/types';
import { DateFormat, DateUtil } from 'src/util/date';
import { checkFileSizeLimit20MB } from 'src/util/file';
import { Validator } from 'src/util/validator';

type Props = {
  studentInfo?: ResponseUserDto;
};

export function useTeacherStudentUpdate() {
  const setToastMsg = useSetRecoilState(toastState);

  const [isEditMode, setIsEditMode] = useState(false);
  const [studentInfo, setStudentInfo] = useState<any>();
  const [studentId, setStudentId] = useState<any>();
  const [name, setName] = useState(studentInfo?.name);
  const [nickName, setNickName] = useState(studentInfo?.nickName);
  const [phone, setPhone] = useState(studentInfo?.phone);
  const [barcode, setBarcode] = useState(studentInfo?.barcode);
  const [hopePath, setHopePath] = useState(studentInfo?.hopePath);
  const [hopeMajor, setHopeMajor] = useState(studentInfo?.hopeMajor);
  const [nokName, setNokName] = useState(studentInfo?.nokName);
  const [nokPhone, setNokPhone] = useState(studentInfo?.nokPhone);
  const [expired, setExpired] = useState(studentInfo?.expired);
  const [expiredReason, setExpiredReason] = useState(studentInfo?.expiredReason);
  const [profile, setProfile] = useState(studentInfo?.profile);
  const [birthDate, setBirthDate] = useState(
    studentInfo?.birthDate ? DateUtil.formatDate(studentInfo?.birthDate, DateFormat['YYYY-MM-DD']) : '',
  );

  const { handleUploadFile, isUploadLoading } = useFileUpload();

  useEffect(() => {
    if (studentId !== studentInfo?.id) {
      setName(studentInfo?.name);
      setNickName(studentInfo?.nickName);
      setStudentId(studentInfo?.id);
      setPhone(studentInfo?.phone);
      setBarcode(studentInfo?.barcode);
      setHopePath(studentInfo?.hopePath);
      setHopeMajor(studentInfo?.hopeMajor);
      setNokName(studentInfo?.nokName);
      setNokPhone(studentInfo?.nokPhone);
      setExpired(studentInfo?.expired);
      setExpiredReason(studentInfo?.expiredReason);
      setProfile(studentInfo?.profile);
      setBirthDate(studentInfo?.birthDate ? DateUtil.formatDate(studentInfo?.birthDate, DateFormat['YYYY-MM-DD']) : '');
      setIsEditMode(false);
    }
  }, [studentInfo]);

  const { mutate: updateStudentMutate, isLoading } = useCoulselingUpdateStudent({
    mutation: {
      onSuccess: () => {
        setIsEditMode(false);
        setToastMsg('학생 정보가 수정되었습니다.');
      },
      onError: (error) => {
        setToastMsg(error?.message || '학생 정보 수정 중 오류가 발생하였습니다.');
      },
    },
  });

  const updateStudent = () => {
    if (studentInfo) {
      updateStudentMutate({
        id: studentInfo.id,
        data: {
          name: name || '',
          nickName: nickName || '',
          phone: phone || '',
          barcode: barcode || '',
          hopePath: hopePath || '',
          hopeMajor: hopeMajor || '',
          profile: profile || '',
          nokName: nokName || '',
          nokPhone: nokPhone || '',
          expired: expired || false,
          expiredReason: expiredReason || '',
          birthDate: birthDate || '',
        },
      });
    }
  };

  const { mutate: sendParentSignUpV2Mutate } = useCoulselingSendParentSignUpV2({
    mutation: {
      onSuccess: () => {
        alert('보호자 회원가입 메시지 발송이 완료되었습니다.');
      },
      onError: (error) => {
        const errorMsg: errorType | undefined = error?.response?.data as errorType;
        alert(errorMsg?.message || '메시지 발송 중 오류가 발생하였습니다.');
      },
    },
  });

  const toggleImageDelete = () => {
    setProfile('');
  };

  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const selectedImageFiles = (e.target as HTMLInputElement).files;
    if (!selectedImageFiles || !selectedImageFiles.length) {
      return;
    }

    if (!Validator.fileNameRule(selectedImageFiles[0].name)) {
      alert('특수문자(%, &, ?, ~)가 포함된 파일명은 사용할 수 없습니다.');
      return;
    }

    if (!checkFileSizeLimit20MB([selectedImageFiles[0]])) {
      alert('한번에 최대 20MB까지만 업로드 가능합니다.');
      return;
    }

    const imageFileNames = await handleUploadFile(UploadFileTypeEnum['userpictures'], [selectedImageFiles[0]]);

    setProfile(imageFileNames[0]);
  };

  return {
    isEditMode,
    setIsEditMode,
    name,
    setName,
    nickName,
    setNickName,
    phone,
    setPhone,
    barcode,
    setBarcode,
    hopePath,
    setHopePath,
    hopeMajor,
    setHopeMajor,
    nokName,
    setNokName,
    nokPhone,
    setNokPhone,
    expired,
    setExpired,
    expiredReason,
    setExpiredReason,
    profile,
    setProfile,
    birthDate,
    setBirthDate,
    studentInfo,
    setStudentInfo,
    isLoading,
    updateStudent,
    sendParentSignUpV2Mutate,
    handleChangeImage,
    toggleImageDelete,
  };
}
