import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useBrowserStorage } from 'src/hooks/useBrowserStorage';
import { RN } from 'src/lib/rn';
import { childState, isStayLoggedInState, refreshTokenState, tokenState, twoFactorState } from 'src/store';

export function useAuth() {
  const token = useRecoilValue(tokenState);
  const twoFactor = useRecoilValue(twoFactorState);

  return { authenticated: token !== null, twoFactorAuthenticated: twoFactor !== null && twoFactor !== 'false' };
}

export function useLogout() {
  const { removeStorage } = useBrowserStorage();
  const resetToken = useResetRecoilState(tokenState);
  const resetRefreshToken = useResetRecoilState(refreshTokenState);
  const resetTwoFactor = useResetRecoilState(twoFactorState);
  const resetStayedLoggedIn = useResetRecoilState(isStayLoggedInState);
  const resetChild = useResetRecoilState(childState);
  const history = useHistory();
  return () => {
    const tagValue = { schoolId: null, role: null };
    RN.flareLane('setUserId', null);
    RN.flareLane('setTags', tagValue);
    removeStorage('token');
    removeStorage('refreshToken');
    removeStorage('two-factor');
    localStorage.removeItem('childToken');
    localStorage.removeItem('child-user-id');
    localStorage.removeItem('tabType');
    localStorage.removeItem('isStayLoggedIn');
    localStorage.removeItem('reqParent_userInfo');
    resetToken();
    resetRefreshToken();
    resetTwoFactor();
    resetStayedLoggedIn();
    resetChild();
    // window.location.replace('/login');
    history.push('/login');
  };
}

export function usePrevious<T>(value: T): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return windowSize;
}
