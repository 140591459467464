import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import { SuperModal } from 'src/components';
import CertificationBadge from 'src/components/blockchain/CertificationBadge';
import { BackButton, Blank, Section, Textarea, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { OutingDetail } from 'src/components/outing/OutingDetail';
import { useBlockChainDocument } from 'src/container/block-chain-document-status';
import { useTeacherOutingDetail } from 'src/container/teacher-outing-detail';
import { OutingStatus, ResponsePaginatedOutingDto, Role } from 'src/generated/model';
import { useSignedUrl } from 'src/lib/query';
import { meState } from 'src/store';
import { approveButtonType } from 'src/types';
import { DateFormat, DateUtil } from 'src/util/date';
import { PermissionUtil, buttonEnableState } from 'src/util/permission';
import { OutingUpdatePage } from './OutingUpdatePage';

interface OutingDetailPageProps {
  outings?: ResponsePaginatedOutingDto;
  userRole?: Role;
  setOpen: (b: boolean) => void;
  setOutingId: (n: number) => void;
  setAgreeAll: (b: boolean) => void;
}

export function OutingDetailPage({ userRole, outings, setOpen, setOutingId, setAgreeAll }: OutingDetailPageProps) {
  const { id } = useParams<{ id: string }>();
  const me = useRecoilValue(meState);
  const [changeMode, setChangeMode] = useState(false);
  const outing = outings?.items?.filter((el) => el.id === Number(id))[0];
  const { data } = useBlockChainDocument({ referenceTable: 'OUTING', referenceId: Number(id) });

  const {
    deny,
    setDeny,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,
    deleteAppeal,
    setDeleteAppeal,
    errM,
    requestDeleteOuting,
    deleteOuting,
    denyOuting,
    isLoading,
  } = useTeacherOutingDetail(Number(id));

  useEffect(() => {
    setOutingId(Number(id));
    setChangeMode(false);
  }, [id, setOutingId]);

  if (!outing) {
    return <div className="h-screen-14 rounded-lg border bg-white p-5"></div>;
  }

  const isConfirmed = outing?.outingStatus === 'PROCESSED';

  const updatedAt = DateUtil.formatDate(outing.updatedAt, DateFormat['YYYY-MM-DD HH:mm']);
  const startAt = DateUtil.formatDate(outing.startAt, DateFormat['YYYY-MM-DD HH:mm']);
  const endAt = DateUtil.formatDate(outing.endAt, DateFormat['YYYY-MM-DD HH:mm']);

  const { data: approver1Signature } = useSignedUrl(outing?.approver1Signature);
  const { data: approver2Signature } = useSignedUrl(outing?.approver2Signature);
  const { data: approver3Signature } = useSignedUrl(outing?.approver3Signature);
  const { data: approver4Signature } = useSignedUrl(outing?.approver4Signature);
  const { data: approver5Signature } = useSignedUrl(outing?.approver5Signature);

  if (changeMode) {
    return (
      <OutingUpdatePage
        outingData={outing}
        isConfirmed={isConfirmed}
        setChangeMode={(b: boolean) => setChangeMode(b)}
      />
    );
  }

  // 결재권자 인지. 결재라인에 있으면 true, 없으면 false
  const approver =
    outing?.approver1Id === me?.id ||
    outing?.approver2Id === me?.id ||
    outing?.approver3Id === me?.id ||
    outing?.approver4Id === me?.id ||
    outing?.approver5Id === me?.id;

  const approvedLine = [
    outing?.approver1Signature && outing?.approver1Id,
    outing?.approver2Signature && outing?.approver2Id,
    outing?.approver3Signature && outing?.approver3Id,
    outing?.approver4Signature && outing?.approver4Id,
    outing?.approver5Signature && outing?.approver5Id,
  ];
  // 내가 승인한 건 : ture , 승인 안한 건 : false
  const isApproved = approvedLine.includes(me?.id || 0);

  // 승인할 차례 : true, 승인전/승인후 : false
  // 지금은 순서가 없으므로, 결재유무만 판단
  //const nowApprove = outing?.nextApproverId === me?.id;
  const nowApprove = !isApproved;

  // 승인 전 = !isApproved && !nowApprove
  // 승인 후 = isApproved && !nowApprove

  const checkButtonDisable = (bottonType: approveButtonType) => {
    return !buttonEnableState(
      bottonType,
      approver,
      isApproved,
      nowApprove,
      outing?.outingStatus || '',
      outing?.studentGradeKlass === me?.klassGroupName,
    );
  };

  return (
    <>
      {isLoading && <Blank reversed />}

      <div className="md:hidden">
        <TopNavbar title="상세보기" left={<BackButton />} />
      </div>
      <div className=" bg-white py-5 md:m-6 md:h-screen-3  md:rounded-lg md:border ">
        <div className="h-screen-13 overflow-y-auto md:h-screen-8">
          <Section>
            {outing?.updateReason && (
              <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
                <div className="text-brand-1">{outing?.updateReason}</div>
                <div className="text-sm text-gray-500">{updatedAt}에 마지막으로 수정</div>
              </div>
            )}
            {outing?.outingStatus === 'RETURNED' && (
              <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
                <div className="text-sm text-brand-1">{outing?.notApprovedReason}</div>
                <div className="text-red-500">반려 이유</div>
              </div>
            )}
            {data?.status && data?.status !== 'NO_DATA' && (
              <div className="flex justify-end">
                <CertificationBadge status={data?.status} />
              </div>
            )}
            <OutingDetail outing={outing} />

            {/* <div className="w-full min-w-max text-center text-3xl font-bold">
              {outing?.type ? outing.type + '증' : ''}
            </div>
            <div className="text-xl font-bold">
              [{outing?.type}] {outing?.student?.name} {outing?.studentGradeKlass} {outing?.studentNumber}번
            </div>

            <table className="w-full text-center ">
              <tbody>
                <tr>
                  <td className="w-1/3 border border-gray-900 p-2 text-center font-bold text-gray-800">이름</td>
                  <td className="w-2/3 border border-gray-900 p-2 text-center font-bold text-gray-500">
                    {outing?.studentName}
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 border border-gray-900 p-2 text-center font-bold text-gray-800">학번</td>
                  <td className="w-2/3 border border-gray-900 p-2 text-center font-bold text-gray-500">
                    {outing?.studentGradeKlass} {outing?.studentNumber}번
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 border border-gray-900 p-2 text-center font-bold text-gray-800">유형</td>
                  <td className="w-2/3 border border-gray-900 p-2 text-center font-bold text-gray-500">
                    {outing?.type === OutingTypeEnum.확인 && outing?.type2} {outing?.type}
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 border border-gray-900 p-2 text-center font-bold text-gray-800">사유</td>
                  <td className="w-2/3 border border-gray-900 p-2 text-center font-bold text-gray-500">
                    {outing?.reason}
                  </td>
                </tr>
                <tr>
                  <td className="w-1/3 border border-gray-900 p-2 text-center font-bold text-gray-800">일시</td>
                  <td className="w-2/3 border border-gray-900 p-2 text-center font-bold text-gray-500">
                    {startAt}&nbsp;~&nbsp;{endAt}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="my-6">
              {outing?.approver1Title && (
                <div className="flex w-full items-center justify-end space-x-2">
                  <div>
                    <div className="flex w-full flex-col items-end">
                      {
                        <div
                          className="h-20 bg-contain bg-right bg-no-repeat"
                          style={{ backgroundImage: `url(${approver1Signature})` }}
                        >
                          <div className="mr-10 mt-5 min-w-max text-right font-bold">
                            {outing?.approver1Title}: {outing?.approver1Name} &nbsp;&nbsp;&nbsp;
                            {outing?.approver1Signature ? ' (인)' : '승인 전'}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}

              {outing?.approver2Title && (
                <div className="flex w-full items-center justify-end space-x-2">
                  <div>
                    <div className="flex w-full flex-col items-end">
                      {
                        <div
                          className="h-20 bg-contain bg-right bg-no-repeat"
                          style={{ backgroundImage: `url(${approver2Signature})` }}
                        >
                          <div className="mr-10 mt-5 min-w-max text-right font-bold">
                            {outing?.approver2Title}: {outing?.approver2Name} &nbsp;&nbsp;&nbsp;
                            {outing?.approver2Signature ? ' (인)' : '승인 전'}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}

              {outing?.approver3Title && (
                <div className="flex w-full items-center justify-end space-x-2">
                  <div>
                    <div className="flex w-full flex-col items-end">
                      {
                        <div
                          className="h-20 bg-contain bg-right bg-no-repeat"
                          style={{ backgroundImage: `url(${approver3Signature})` }}
                        >
                          <div className="mr-10 mt-5 min-w-max text-right font-bold">
                            {outing?.approver3Title}: {outing?.approver3Name} &nbsp;&nbsp;&nbsp;
                            {outing?.approver3Signature ? ' (인)' : '승인 전'}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}

              {outing?.approver4Title && (
                <div className="flex w-full items-center justify-end space-x-2">
                  <div>
                    <div className="flex w-full flex-col items-end">
                      {
                        <div
                          className="h-20 bg-contain bg-right bg-no-repeat"
                          style={{ backgroundImage: `url(${approver4Signature})` }}
                        >
                          <div className="mr-10 mt-5 min-w-max text-right font-bold">
                            {outing?.approver4Title}: {outing?.approver4Name} &nbsp;&nbsp;&nbsp;
                            {outing?.approver4Signature ? ' (인)' : '승인 전'}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}

              {outing?.approver5Title && (
                <div className="flex w-full items-center justify-end space-x-2">
                  <div>
                    <div className="flex w-full flex-col items-end">
                      {
                        <div
                          className="h-20 bg-contain bg-right bg-no-repeat"
                          style={{ backgroundImage: `url(${approver5Signature})` }}
                        >
                          <div className="mr-10 mt-5 min-w-max text-right font-bold">
                            {outing?.approver5Title}: {outing?.approver5Name} &nbsp;&nbsp;&nbsp;
                            {outing?.approver5Signature ? ' (인)' : '승인 전'}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </div> */}
          </Section>
        </div>

        {errM && <div className="text-red-500">{errM}</div>}
        <div className="grid grid-cols-4 gap-2 px-2 pt-3 md:px-0">
          {PermissionUtil.hasOutingAuthorization(userRole) && (
            <>
              {outing?.writerName === me?.name && outing?.outingStatus !== OutingStatus.PROCESSED ? (
                <Button.xl
                  children={'삭제'}
                  onClick={() => {
                    if (confirm('확인증을 삭제하시겠습니까?')) {
                      deleteOuting();
                    }
                  }}
                  className="filled-red"
                />
              ) : (
                <Button.xl
                  children={outing?.outingStatus === 'DELETE_APPEAL' ? '삭제대기' : '삭제요청'}
                  disabled={checkButtonDisable(approveButtonType.DELETE)}
                  onClick={() => setDeleteAppeal(true)}
                  className="filled-red"
                />
              )}
              <Button.xl
                children={outing?.outingStatus === 'RETURNED' ? '반려됨' : '반려'}
                disabled={checkButtonDisable(approveButtonType.RETURN)}
                onClick={() => setDeny(true)}
                className="filled-blue"
              />
              <Button.xl
                children={isConfirmed ? '승인 후 수정' : '수정'}
                disabled={checkButtonDisable(approveButtonType.EDIT)}
                onClick={() => setChangeMode(true)}
                className="filled-yellow"
              />
              <Button.xl
                children={outing?.outingStatus === 'PROCESSED' ? '승인 완료' : '승인'}
                disabled={checkButtonDisable(approveButtonType.APPROVE)}
                onClick={() => {
                  setOpen(true);
                  setAgreeAll(false);
                }}
                className="filled-primary"
              />
            </>
          )}
        </div>

        <SuperModal modalOpen={deny} setModalClose={() => setDeny(false)} width="w-max">
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              이 학생의 확인증을 반려하시겠습니까?
            </div>
            <Textarea
              placeholder="반려 이유"
              value={notApprovedReason}
              onChange={(e) => setNotApprovedReason(e.target.value)}
            />
            <Button.xl
              children="반려하기"
              disabled={!notApprovedReason}
              onClick={() => denyOuting()}
              className="filled-primary"
            />
          </Section>
        </SuperModal>
        <SuperModal modalOpen={deleteAppeal} setModalClose={() => setDeleteAppeal(false)} width="w-max">
          <Section className="mt-7">
            <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
              이 확인증을 삭제하도록 요청하시겠습니까?
            </div>
            <Textarea placeholder="삭제 이유" value={deleteReason} onChange={(e) => setDeleteReason(e.target.value)} />
            <span className="text-sm text-red-400">* 교사가 삭제요청하면 학생 또는 보호자가 삭제할 수 있습니다.</span>
            <Button.xl
              children="삭제 요청하기"
              disabled={!deleteReason}
              onClick={() => requestDeleteOuting()}
              className="filled-red"
            />
          </Section>
        </SuperModal>
      </div>
    </>
  );
}
