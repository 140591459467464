import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Label, Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { NumberInput } from 'src/components/common/NumberInput';
import { TextInput } from 'src/components/common/TextInput';
import { useCodeByCategoryName } from 'src/container/category';
import {
  studentManagementCreateStudent,
  studentManagementUpdateStudent,
  useStudentManagementGetStudentInfo,
} from 'src/generated/endpoint';
import { Category, RequestCreateStudentDto, RequestModifyStudentDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { form } from 'src/lib/form';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { getErrorMsg } from 'src/util/status';
import { AdminContext } from '../AdminMainPage';

export function StudentEditPage() {
  const { goBack } = useHistory();
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);
  const { year } = useContext(AdminContext);
  const { t } = useLanguage();

  const setToastMsg = useSetRecoilState(toastState);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<RequestModifyStudentDto & RequestCreateStudentDto>();

  const { categoryData: studentStates } = useCodeByCategoryName(Category.studentstatus);
  const { data: studentInfo } = useStudentManagementGetStudentInfo(id, { year }, { query: { enabled: !!id } });

  useEffect(() => studentInfo && reset(studentInfo.studentData), [studentInfo]);

  async function save(params: any) {
    id
      ? await studentManagementUpdateStudent(id, params)
          .then((result) => {
            setToastMsg(`${params.name} 님 수정완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          })
      : await studentManagementCreateStudent({ ...params, year })
          .then((result) => {
            setToastMsg(`${params.name} 님 추가완료`);
            goBack();
          })
          .catch((result) => {
            setToastMsg(getErrorMsg(result));
          });
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">
        {t('student')} {id ? t('edit') : t('add')}
      </Admin.H2>

      <Label.col>
        <Label.Text children={'*' + t('name')} />
        <TextInput placeholder={`${t('name')}`} {...register('name', form.length(1, 100))} />
        <Label.Error children={errors.name?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={'*' + t('email')} />
        <TextInput placeholder={`${t('email')}`} {...register('email', form.length(1, 100))} />
        <Label.Error children={errors.email?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={t('phone_number')} />
        <TextInput placeholder={`${t('phone_number')}`} {...register('phone', form.length(0, 100))} />
        <Label.Error children={errors.phone?.message} />
      </Label.col>
      {!id && (
        <Label.col>
          <Label.Text children={'*' + t('grade')} />
          <NumberInput placeholder={`${t('grade')}`} {...register('grade', form.minmax(1, 20))} />
          <Label.Error children={errors.grade?.message} />
        </Label.col>
      )}
      {!id && (
        <Label.col>
          <Label.Text children={'*' + t('class_section')} />
          <NumberInput placeholder={`${t('class_section')}`} {...register('klass', form.minmax(1, 100))} />
          <Label.Error children={errors.klass?.message} />
        </Label.col>
      )}
      {!id && (
        <Label.col>
          <Label.Text children={'*' + t('attendance_number')} />
          <NumberInput placeholder={`${t('attendance_number')}`} {...register('studentNumber', form.minmax(1, 100))} />
          <Label.Error children={errors.studentNumber?.message} />
        </Label.col>
      )}
      {!!id && studentStates && (
        <Label.col>
          <Label.Text children={t('academic_status')} />
          <Select.lg
            {...register('expiredReason', {
              onChange: (e) => {
                const state = studentStates.find((s) => s.name === e.target.value);
                setValue('expired', state?.etc1 === 'true');
                setValue('expiredReason', e.target.value);
              },
            })}
            className="h-13"
          >
            <option value="">{t('academic_status')}</option>
            {studentStates?.map((state) => (
              <option key={state.id} value={state.name}>
                {t(`Student_Status.${state.name}`)}
              </option>
            ))}
          </Select.lg>
        </Label.col>
      )}
      <Label.col>
        <Label.Text children={t('parent_name')} />
        <TextInput placeholder={`${t('parent_name')}`} {...register('nokName', form.length(0, 100))} />
        <Label.Error children={errors.nokName?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={t('parent_phone_number')} />
        <TextInput placeholder={`${t('parent_phone_number')}`} {...register('nokPhone', form.length(0, 100))} />
        <Label.Error children={errors.nokPhone?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={t('member_barcode')} />
        <TextInput placeholder={`${t('member_barcode')}`} {...register('barcode', form.length(0, 100))} />
        <Label.Error children={errors.barcode?.message} />
      </Label.col>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <Link
          children={t('cancel')}
          to={id ? `../${id}` : Routes.admin.student.index}
          className="button-lg outlined-gray"
        />
        <Button.lg children={t('save')} disabled={!isValid} onClick={handleSubmit(save)} className="filled-gray" />
      </div>
    </Admin.Section>
  );
}
