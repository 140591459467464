import { useBlockChainGetDocumentStatus } from 'src/generated/endpoint';
import { BlockChainGetDocumentStatusParams } from 'src/generated/model';

export function useBlockChainDocument({ referenceTable, referenceId }: BlockChainGetDocumentStatusParams) {
  const { data, isLoading } = useBlockChainGetDocumentStatus({ referenceTable, referenceId });

  return {
    data,
    isLoading,
  };
}
