import { useBlockChainCreateWallet } from 'src/generated/endpoint';

export function useBlockChainWallet() {
  const { mutate: createWallet, isLoading: isCreating, isError, error } = useBlockChainCreateWallet();

  return {
    createWallet,
    isCreating,
    isError,
    error,
  };
}
