import { useState } from 'react';
import {
  useTimetablev3GetTimetableByGroupId,
  useTimetablev3GetTimetableByTeacherId,
  useUserGetAllTeachers,
} from 'src/generated/endpoint';
import { ResponseTeachersDto } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';
import { UserContainer } from './user';

export function useTeacherTimetableDetail(type = '') {
  const { me, isMeLoading } = UserContainer.useContext();

  const [groupId, setGroupId] = useState(me?.klassGroupId || 0);
  const [teacher, setTeacher] = useState<ResponseTeachersDto>();
  const [teacherId, setTeacherId] = useState(me?.id || 0);

  const {
    data: teachers,
    isLoading: isTeacherLoading,
    error: errorTeacher,
  } = useUserGetAllTeachers<ResponseTeachersDto[]>();

  // 학급별 시간표 V3
  const {
    data: timetableV3Klass,
    isLoading: isTimetableLoadingV3Klass,
    error: errorTimetableV3Klass,
  } = useTimetablev3GetTimetableByGroupId(
    groupId,
    {
      date: DateUtil.formatDate(new Date(), DateFormat['YYYY-MM-DD']),
    },
    {
      query: {
        queryKey: [groupId],
        enabled: !!groupId,
      },
    },
  );

  // 교사별 시간표 V3
  const {
    data: timetableV3Teacher,
    isLoading: isTimetableLoadingV3Teacher,
    error: errorTimetableV3Teacher,
  } = useTimetablev3GetTimetableByTeacherId(
    teacherId,
    {
      type,
      date: DateUtil.formatDate(new Date(), DateFormat['YYYY-MM-DD']),
    },
    {
      query: {
        queryKey: [teacherId],
        enabled: !!teacherId,
      },
    },
  );

  const changeTeacher = (teacherId: number) => {
    setTeacherId(teacherId);
    setTeacher(teachers?.find((teacher) => teacher.id === teacherId));
  };

  const changeKlass = (klassId: number) => {
    setGroupId(klassId);
  };

  return {
    teachers: teachers
      ?.reduce((acc: any[], current: any) => {
        if (!acc.find((item) => item.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, [])
      ?.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      }),
    groupId,
    teacher,
    teacherId,
    setTeacherId,
    timetableV3Klass,
    timetableV3Teacher,
    isLoading: isTimetableLoadingV3Teacher || isTimetableLoadingV3Klass || isMeLoading,
    error: errorTimetableV3Teacher || errorTimetableV3Klass,
    changeTeacher,
    changeKlass,
  };
}
