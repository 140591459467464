import { useEffect, useState } from 'react';
import {
  useCoulselingCreate,
  useCoulselingDelete,
  useCoulselingFindByStudentId,
  useCoulselingUpdate,
} from 'src/generated/endpoint';
import { ResponseCoulselingDetailDto } from 'src/generated/model';
import { DateFormat, DateUtil } from 'src/util/date';

export function useTeacherCoulseling(studentId?: number) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editId, setEditId] = useState<number | undefined>();
  const [isAddMode, setIsAddMode] = useState(false);
  const [content, setContent] = useState('');
  const [coulselingAt, setCoulselingAt] = useState(DateUtil.formatDate(new Date(), DateFormat['YYYY-MM-DD']));
  const [category, setCategory] = useState('');
  const [coulselorName, setCoulselorName] = useState('');
  const [accessLevel, setAccessLevel] = useState(0);
  const [userId, setUserId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsEditMode(false);
    setIsAddMode(false);
    setUserId(studentId || 0);
  }, [studentId]);

  useEffect(() => {
    if (!isEditMode || (isEditMode && !isAddMode)) {
      setIsEditMode(false);
      setEditId(undefined);
    }
  }, [isEditMode, isAddMode]);

  const { data: coulseingData, refetch } = useCoulselingFindByStudentId<ResponseCoulselingDetailDto[]>(studentId || 0, {
    query: {
      enabled: !!studentId,
    },
  });

  const { mutate: createCoulselingMutate } = useCoulselingCreate({
    mutation: {
      onMutate: () => {
        setIsLoading(true); // 로딩 시작
      },
      onSuccess: () => {
        refetch()
          .then(() => {
            setIsAddMode(false);
            alert('상담이 추가 되었습니다.');
          })
          .finally(() => {
            setIsLoading(false); // 로딩 종료
          });
      },
      onError: () => {
        setIsLoading(false); // 실패 시 로딩 종료
        alert('상담 생성에 실패했습니다. 다시 시도해 주세요.');
      },
    },
  });

  const createCoulseling = (uploadedFileNames: string[] = []) => {
    if (studentId) {
      createCoulselingMutate({
        data: {
          content,
          voiceFiles: uploadedFileNames,
          coulselingAt,
          category,
          coulselorName,
          userId,
          accessLevel,
        },
      });
    }
  };

  const { mutate: updateCoulselingMutate } = useCoulselingUpdate({
    mutation: {
      onMutate: () => {
        setIsLoading(true); // 로딩 시작
      },
      onSuccess: () => {
        refetch()
          .then(() => {
            alert('수정되었습니다.');
          })
          .finally(() => {
            setIsLoading(false); // 로딩 종료
          });
      },
      onError: () => {
        setIsLoading(false); // 실패 시 로딩 종료
        alert('상담 수정에 실패했습니다. 다시 시도해 주세요.');
      },
    },
  });

  const updateCoulseling = (id: number) => {
    updateCoulselingMutate({
      id,
      data: {
        content,
        voiceFiles: [],
        coulselingAt,
        category,
        coulselorName,
        userId,
        accessLevel,
      },
    });
  };

  const { mutate: deleteCoulselingMutate } = useCoulselingDelete({
    mutation: {
      onMutate: () => {
        setIsLoading(true); // 로딩 시작
      },
      onSuccess: () => {
        refetch()
          .then(() => {
            alert('삭제되었습니다.');
          })
          .finally(() => {
            setIsLoading(false); // 로딩 종료
          });
      },
      onError: () => {
        setIsLoading(false); // 실패 시 로딩 종료
        alert('상담 삭제에 실패했습니다. 다시 시도해 주세요.');
      },
    },
  });

  const deleteCoulseling = (id: number) => {
    deleteCoulselingMutate({ id });
  };

  return {
    isEditMode,
    setIsEditMode,
    editId,
    setEditId,
    isAddMode,
    setIsAddMode,
    content,
    setContent,
    coulselingAt,
    setCoulselingAt,
    category,
    setCategory,
    coulselorName,
    setCoulselorName,
    userId,
    setUserId,
    accessLevel,
    setAccessLevel,
    coulseingData,
    createCoulseling,
    updateCoulseling,
    deleteCoulseling,
    refetch,
    isLoading,
  };
}
